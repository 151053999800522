"use strict";

import Vue from "vue";
import axios from "axios";

import router from "@/router";
import { Message } from "element-ui";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  //baseURL: "/api",
  baseURL: "https://m.tapxsoft.com",
  //baseURL: "http://192.168.2.190:8848",
  timeout: 30 * 1000 // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    // Do something before request is sent
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    config.headers["appId"] = 4;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.data.code == -1) {
      localStorage.removeItem("token");
      router.push("/login");
    }
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.config.timeout) {
      const language = navigator.language;
      if (
        language == "zh-CN" ||
        language == "zh-TW" ||
        language == "zh-HK" ||
        language == "zh-MO" ||
        language == "zh-SG" ||
        language == "zh"
      ) {
        Message({
          showClose: true,
          message: "请求超时，请检查网络",
          type: "error",
          center: true
        });
      } else {
        Message({
          showClose: true,
          message: "Request timed out, please check network",
          type: "error",
          center: true
        });
      }
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
