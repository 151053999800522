<template>
  <div id="task-layout" v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="t-page-header">
      <el-page-header :title="$t('common.i_back')" :content="$t('task.i_task')" @back="goBack" style="float: left;"></el-page-header>
      <div style="float: right;height: 24px;">
        <svg style="vertical-align: middle;" t="1655452993620" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2356" width="24" height="24">
          <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2357"></path>
          <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2358"></path>
        </svg>
        <router-link to="/goldLog" style="text-decoration: none;color: black;vertical-align: middle;">{{$t('index.i_coinsLog')}}</router-link>
      </div>
    </div>

    <div style="margin-top: 8%">{{$t('index.i_totalCoins')}}</div>

    <div style="margin-top: 4%;">
      <img src="@/assets/common/moneyIcon.png" style="width: 30px;vertical-align: middle;" />
      <span style="font-size: 30px;color: rgba(0, 0, 0, 1);font-weight: bold;margin-left: 4px;vertical-align: middle;">{{$store.state.goldNum}}</span>
    </div>

    <div style="margin-top: 6%;">
      <el-button :class="taskShow==0?'taskAct':''" @click="taskShow=0" style="border: 1px solid rgba(62, 72, 103, 1);border-radius: 6px;min-width: 124px;">{{$t('task.i_dailyTasks')}}</el-button>
      <el-button :class="taskShow==1?'taskAct':''" @click="taskShow=1" style="border: 1px solid rgba(62, 72, 103, 1);border-radius: 6px;min-width: 124px;">{{$t('task.i_achievement')}}</el-button>
    </div>

    <div style="margin-top: 6%;">
      <div v-if="taskShow == 0">
        <div v-for="task in taskData.dayTasks" :key="task.id" style="background: rgba(222, 231, 233, 1);margin-top: 3%;border-radius: 8px;padding: 2%;height: 60px;">
          <div style="overflow: hidden;">
            <div style="float: left;font-size: 16px;margin-top: 6px;margin-left: 2%;">
              <div>{{task.taskTitle}}({{task.currentNum}}/{{task.taskCondition}})</div>
              <div style="margin-top: 7px;text-align: left;">
                <span style="vertical-align: middle;">+{{task.taskRewardGold}} </span>
                <img src="@/assets/common/moneyIcon.png" style="width: 20px;vertical-align: middle;" />
              </div>
            </div>
            <div style="float: right;margin-top: 10px;margin-right: 2%;">
              <el-button v-if="task.currentNum>=task.taskCondition" @click="goAppType=1;goAppDialog = true" style="background-color: rgba(255, 180, 20, 1);border-radius: 30px;color: black;min-width: 96px;">{{$t('task.i_getNow')}}</el-button>
              <el-button v-else @click="goAppType=0;goAppDialog = true" style="background-color: rgba(60, 88, 154, 1);border-radius: 30px;color: white;min-width: 96px;">{{$t('task.i_goNow')}}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="taskShow == 1">
        <div v-for="task in taskData.achievementTasks" :key="task.id" style="background: rgba(222, 231, 233, 1);margin-top: 3%;border-radius: 8px;padding: 2%;height: 60px;">
          <div style="overflow: hidden;">
            <div style="float: left;font-size: 16px;margin-top: 6px;margin-left: 2%;">
              <div>{{task.taskTitle}}({{task.currentNum}}/{{task.taskCondition}})</div>
              <div style="margin-top: 7px;text-align: left;">
                <span style="vertical-align: middle;">+{{task.taskRewardGold}} </span>
                <img src="@/assets/common/moneyIcon.png" style="width: 20px;vertical-align: middle;" />
              </div>
            </div>
            <div style="float: right;margin-top: 10px;margin-right: 2%;">
              <el-button v-if="task.currentNum>=task.taskCondition" @click="goAppType=1;goAppDialog = true" style="background-color: rgba(255, 180, 20, 1);border-radius: 30px;color: black;min-width: 96px;">{{$t('task.i_getNow')}}</el-button>
              <el-button v-else @click="goAppType=0;goAppDialog = true" style="background-color: rgba(60, 88, 154, 1);border-radius: 30px;color: white;min-width: 96px;">{{$t('task.i_goNow')}}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="goAppType==0?$t('moenySteps.i_actTips'):$t('task.i_goAppGet')" :visible.sync="goAppDialog" width="80%" center>
      <div style="text-align: center;">
        <img v-if="goAppType==0" src="@/assets/images/task001.png" style="width: 30%;" />
        <img v-if="goAppType==1" src="@/assets/images/task002.png" style="width: 30%;" />
      </div>
      <div style="text-align: center;margin-top: 10%;">
        <el-button type="primary" @click="goApp()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">{{$t('convert.i_ok')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="bindDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="padding: 6%;text-align: center;margin-bottom: 6%;">
        <div>
          <span style="font-size: 16px;color: #000;">{{$t('convert.i_activateMsg')}}</span>
        </div>
      </div>
      <div style="text-align: center;">
        <el-button type="primary" @click="$router.push('/moneySteps')" style="width: 60%;">{{$t('convert.i_ok')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      taskShow: 0,
      taskData: {},
      goAppDialog: false,
      urlSchemes: "",
      goAppType: 0,
      bindDialog: false
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", this.isVisible);
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.isVisible);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getTaskList() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/web/task",
        method: "get",
        params: {
          pageNum: 1
        }
      }).then((result) => {
        this.isLoading = false;
        console.log(result.data);
        this.taskData = result.data.data;
        if (result.data.code == 1) {
          this.$store.state.goldNum = result.data.data.goldNum;
          this.urlSchemes = result.data.data.urlSchemes;
        } else if (result.data.code == 2) {
          this.$store.state.goldNum = result.data.data.goldNum;
          this.bindDialog = true;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    goApp() {
      if (this.goAppType == 0) {
        window.location.href = this.urlSchemes;
      } else if (this.goAppType == 1) {
        window.location.href = this.urlSchemes + "award";
      }
      this.goAppDialog = false;
    },
    isVisible: function (e) {
      if (e.target.visibilityState === "visible") {
        // 切入页面 显示页面
        this.getTaskList();
      } else if (e.target.visibilityState === "hidden") {
        // 切出页面 隐藏页面
      }
    }
  },
  created() {
    this.getTaskList();
  }
};
</script>

<style>
#task-layout {
  background: rgba(255, 255, 255, 1);
  padding: 4%;
}
#task-layout .t-page-header {
  overflow: hidden;
}
#task-layout .taskAct {
  background-color: rgba(62, 72, 103, 1) !important;
  color: white !important;
}
#task-layout .el-dialog {
  border-radius: 15px !important;
}
#task-layout .el-dialog__header {
  background: white !important;
  border-radius: 15px 15px 0 0 !important;
}
#task-layout .el-dialog__body {
  background: white !important;
  border-radius: 0 0 15px 15px !important;
}
</style>
