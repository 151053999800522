<template>
  <div>

    <button @click="inviteLogDialog=true">inviteLogDialog</button>
    <el-dialog title="邀请记录" :visible.sync="inviteLogDialog" width="90%" center>
    <el-tabs type="border-card">
        <el-tab-pane label="已激活" style="height: 340px;overflow-y: scroll;">
         <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
    <div v-for="item in data" :key="item.index">{{item.name}}</div>
</div>
        </el-tab-pane>
      </el-tabs>
    
    </el-dialog>
    
   
  </div>

</template>

<style>

</style>

<script>
export default {
  data () {
    return {
      count: 1,
      data: [],
      busy: false,
      pageNum: 1,
      inviteLogDialog:false
    }
  },
  methods: {
    loadMore: function() {
      
      if(this.pageNum<=10) {

        this.busy = true
        console.log(this.pageNum++)
      // setTimeout(() => {
        for(var i=1;i<=50;i++) {
          this.data.push({name: this.count++ })
        }
        console.log(this.data)
        this.busy = false
      // }, 1000)
      }
      
    }
  }
};
</script>
