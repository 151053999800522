<template>
  <div v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="register-layout">
      <el-page-header :title="$t('common.i_back')" :content="$t('register.i_register')" @back="goBack" class="r-page-header"></el-page-header>
      <div class="register-form">
        <el-form ref="registerForm" :model="registerForm" :rules="registerRules">
          <div style="display: none;">
            <!-- Email -->
            <el-form-item :label="$t('register.i_enterYourEmail')" prop="email">
              <el-input type="mail" v-model="registerForm.email" :placeholder="$t('register.i_enterYourEmail')"></el-input>
            </el-form-item>
            <!-- 验证码 -->
            <el-form-item :label="$t('register.i_getVerifyCode')" prop="verifyCode">
              <Vcode :show="isShow" @success="vCodeSuccess()" :sliderText="$t('vCode.i_sliderText')" :failText="$t('vCode.i_failText')" :successText="$t('vCode.i_successText')" @close="close"></Vcode>
              <div style="overflow: hidden;width: 100%;">
                <div style="float: left;width: 54%;">
                  <el-input type="number" v-model="registerForm.verifyCode" :placeholder="$t('register.i_enterVerifyCode')"></el-input>
                </div>
                <div style="float: left;width: 44%;margin-left: 2%;">
                  <el-button type="primary" style="width: 100%;" @click="vCodeOpen('registerForm')" :disabled="getVerifyCodeDisabled">{{verifyCodeText}}</el-button>
                </div>
              </div>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item :label="$t('register.i_enterPass')" prop="pass">
              <el-input type="password" v-model="registerForm.pass" show-password :placeholder="$t('register.i_enterPass')"></el-input>
            </el-form-item>
            <!-- 确认密码 -->
            <el-form-item :label="$t('register.i_checkYourPass')" prop="checkPass">
              <el-input type="password" v-model="registerForm.checkPass" show-password :placeholder="$t('register.i_checkYourPass')"></el-input>
            </el-form-item>
            <!-- 邀请码 -->
            <el-form-item :label="$t('register.i_inviteCodeT')" prop="inviteCode">
              <el-input type="text" v-model="registerForm.inviteCode" :placeholder="$t('register.i_enterInviteCode')"></el-input>
            </el-form-item>
            <!-- 是否自动登录 -->
            <div style="text-align: left;margin-bottom: 10%;">
              <el-checkbox v-model="registerForm.autoLogin">{{$t('register.i_autoLogin')}}</el-checkbox>
            </div>
            <!-- 立即注册 -->
            <el-form-item>
              <el-button type="primary" @click="register('registerForm')" style="width: 60%;height: 50px;">{{$t('register.i_goRegister')}}</el-button>
            </el-form-item>
          </div>

          <div style="margin-top: 10px;">
            <div style="text-align: left;font-size: 22px;">
              <div style="">
                {{$t('register.i_pleaseUseNewPlatform')}}：
              </div>
              <a :href="webCashUrl" style="word-break: break-all;">
                {{webCashUrl}}
              </a>
            </div>
            <el-button @click="goUrl()" style="width: 60%;height: 50px;background-color: #009EDF;color: white;margin-top: 10px;">
              Go
            </el-button>
          </div>

          <!-- 前往登录 -->
          <el-form-item>
            <router-link to="/login" style="color: rgba(0, 158, 223, 1);">{{$t('register.i_goLogin')}}</router-link>
          </el-form-item>
        </el-form>
      </div>

      <el-dialog :visible.sync="centerDialogVisible" width="80%" center>
        <el-alert v-if="isInviteAlert==true" :title="isInviteAlertTitle" type="error" center :closable="false" style="margin-bottom: 3%;"></el-alert>
        <div style="width: 100%;text-align: center;">
          <img src="@/assets/common/success.png" style="width: 20%;" />
          <div style="font-size: 18px;margin-top: 5%;margin-bottom: 10%;">{{$t('register.i_registeredSuccess')}}<br />{{$t('register.i_startEarning')}}</div>
        </div>

        <div style="text-align: center;">
          <router-link to="/index">
            <el-button type="success" style="width: 80%;">{{$t('register.i_goLogin')}}</el-button>
          </router-link>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
export default {
  components: {
    Vcode
  },
  data() {
    const validatorVerifyCode = (rule, value, callback) => {
      if (value.length != 6) {
        callback(new Error(this.$t("register.i_enter6VerifyCode")));
      } else {
        callback();
      }
    };
    const validatorPass = (rule, value, callback) => {
      var regex =
        /^[0-9]*$|^[a-zA-Z]*$|^[~!@#$%^&*]*$|^[A-Za-z0-9]*$|^[0-9~!@#$%^&*]*$|^[a-zA-Z~!@#$%^&*]*$|^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]*$/;
      if (!regex.test(value)) {
        callback(new Error(this.$t("register.i_specialSymbols")));
      } else if (value.length < 6) {
        callback(new Error(this.$t("register.i_pass6")));
      } else if (value.length > 30) {
        callback(new Error(this.$t("register.i_pass30")));
      } else {
        callback();
      }
    };
    const validatorCheckPass = (rule, value, callback) => {
      if (value !== this.registerForm.pass) {
        callback(new Error(this.$t("register.i_checkTwoPass")));
      } else {
        callback();
      }
    };
    return {
      isLoading: false,
      centerDialogVisible: false,
      // 验证码模态框是否出现
      isShow: false,
      isInviteAlert: false,
      isInviteAlertTitle: "",
      verifyCodeText: this.$t("register.i_getVerifyCode"),
      timeCount: 60,
      timer: null,
      getVerifyCodeDisabled: false,
      registerForm: {
        email: "",
        verifyCode: "",
        pass: "",
        checkPass: "",
        inviteCode: "",
        autoLogin: true
      },
      registerRules: {
        email: [
          {
            required: true,
            message: this.$t("register.i_emailAddressEmpty"),
            trigger: ["blur", "change"]
          },
          {
            type: "email",
            message: this.$t("register.i_checkEmail"),
            trigger: ["blur", "change"]
          }
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t("register.i_verifyCodeEmpty"),
            trigger: ["blur", "change"]
          },
          {
            validator: validatorVerifyCode,
            trigger: ["blur", "change"]
          }
        ],
        pass: [
          {
            required: true,
            message: this.$t("register.i_passEmpty"),
            trigger: ["blur", "change"]
          },
          {
            validator: validatorPass,
            trigger: ["blur", "change"]
          }
        ],
        checkPass: [
          {
            required: true,
            message: this.$t("register.i_checkPassEmpty"),
            trigger: ["blur", "change"]
          },
          {
            validator: validatorPass,
            trigger: ["blur", "change"]
          },
          {
            validator: validatorCheckPass,
            trigger: ["blur", "change"]
          }
        ]
      },
      webCashUrl:
        "https://www.bejoyit.com/#/invite?tid=9d75971299c296b8b175631b02d797d0&referrer=44UCY2NZ"
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goUrl() {
      window.location.href = this.webCashUrl;
    },
    vCodeOpen(formName) {
      this.$refs[formName].validateField(["email"], (validErr) => {
        if (!validErr) {
          this.isShow = true;
        }
      });
    },
    close() {
      this.isShow = false;
    },
    vCodeSuccess() {
      this.getVerifyCode("registerForm");
      this.isShow = false;
    },
    getVerifyCode(formName) {
      this.$refs[formName].validateField(["email"], (validErr) => {
        if (!validErr) {
          if (!this.timer) {
            this.getVerifyCodeDisabled = true;
            this.verifyCodeText = this.$t("register.i_inProgress");
            this.$axios({
              url:
                "/webAndGame/webUser/user/getVerifyCode?email=" +
                this.registerForm.email +
                "&type=1",
              method: "get"
            })
              .then((result) => {
                if (result.data.code == 1) {
                  this.getVerifyCodeDisabled = true;
                  this.verifyCodeText =
                    this.timeCount + "s " + this.$t("register.i_reRequest");
                  this.timer = setInterval(() => {
                    if (this.timeCount > 1) {
                      this.getVerifyCodeDisabled = true;
                      this.timeCount--;
                      this.verifyCodeText =
                        this.timeCount + "s " + this.$t("register.i_reRequest");
                    } else {
                      this.getVerifyCodeDisabled = false;
                      this.verifyCodeText = this.$t("register.i_getVerifyCode");
                      clearInterval(this.timer);
                      this.timer = null;
                      this.timeCount = 60;
                    }
                  }, 1000);
                  this.$message({
                    message: this.$t("register.i_verifyCodeMsg"),
                    type: "success",
                    center: true,
                    showClose: true
                  });
                } else {
                  this.getVerifyCodeDisabled = false;
                  this.verifyCodeText = this.$t("register.i_getVerifyCode");
                  this.$message({
                    showClose: true,
                    message: result.data.message,
                    type: "error",
                    center: true
                  });
                }
              })
              .catch((error) => {
                this.getVerifyCodeDisabled = false;
                this.verifyCodeText = this.$t("register.i_getVerifyCode");
              });
          }
        }
      });
    },
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.$axios({
            url: "/webAndGame/webUser/user/register",
            method: "post",
            params: this.registerForm
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              this.centerDialogVisible = true;
              if (result.data.data.token != null) {
                localStorage.setItem("token", result.data.data.token);
              }
              if (result.data.data.inviteMsg != null) {
                if (result.data.data.inviteMsg.code == 0) {
                  this.isInviteAlert = true;
                  this.isInviteAlertTitle = result.data.data.inviteMsg.message;
                } else {
                  this.isInviteAlert = false;
                }
              }
            } else {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "error",
                center: true
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  },
  created() {
    this.registerForm.inviteCode = this.$route.query.inviteCode;
  }
};
</script>

<style>
#register-layout {
  background-color: rgba(225, 234, 238, 1);
  height: 100%;
}
.r-page-header {
  padding-top: 4%;
  padding-left: 4%;
  padding-right: 4%;
}
.register-form {
  padding-top: 4%;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 6%;
  background-color: rgba(225, 234, 238, 1);
}
</style>
